import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServicesPage from "../components/services/index"
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        title="404 | innoway"
        desc="404 page innoway"
        keywords="404 page"
      />
      <ServicesPage />
    </Layout>
  )
}

export default NotFoundPage
